.image-card {
  overflow: hidden;
  position: relative;

  & img {
    width: 100%;
    min-height: 100%;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.projects-table {
  min-height: 400px;
  background: #FFFFFF;
  padding: 20px 25px;
  & .status {
    position: relative;
    padding-left: 20px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    &.yellow::before {
      background: #FCC865;
    }
    &.red::before {
      background: #FF575F;
    }
    &.green::before {
      background: #28C397;
    }
    &.gray::before {
      background: #96A0B5;
    }
  }
  & .projects {
    &-row {
      padding-bottom: 26px;
      border-bottom: 1px solid #E7EDF2;
      & h4.title {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2;
        color: #A0AEC0;
        margin-bottom: 8px;
  
        &.project-name {
        color: #051A2D;
        font-weight: 700px;
        }
      }
    }
    &-link {
      display: grid;
      grid-template-columns: 80px 1fr;
      grid-gap: 20px;
    }
  }
  & .image-card {
    width: 80px;
    height: 63px;
    border-radius: 5px;
  }
}
.project-data {
  display: flex;
  flex-direction: column;
}