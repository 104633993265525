.applicationSubmissionModal {
  width: auto !important;

  & svg.check {
    display: block;
    margin: 0 auto 26px;
  }

  & .ant-modal-body {
    text-align: center;
    padding: 60px 110px 60px;
  }

  & .ant-modal-content {
    border-radius: 10px;
  }

  & .modal-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    text-transform: uppercase;
    color: #272727;
    margin-bottom: 40px;
  }

  &-regForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    & h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      color: #2D3748;
    }


    & .ant-input-lg {
      width: 500px;
      padding: 13px 20px;
      background: rgba(237, 242, 247, 0.8);
      border-radius: 10px;
      border: none;
    }

    & button {
      height: auto !important;
      padding: 20px 30px;
      background: #128FFF;
      border-radius: 10px;

      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-top: 40px;
    }
  }
}