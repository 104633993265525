.create-project {
  & .ant-modal-mask {
    background: rgba(0, 43, 111, 0.8) !important;
  }

  & .header-landing {
    margin-bottom: 20px;

    &-inner {
      position: static;
    }
  }

  & .image-card {
    overflow: hidden;
    position: relative;

    & img {
      width: 100%;
      min-height: 100%;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & .image-card-preview {
    overflow: hidden;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: auto;
      max-height: 100%;
      position: absolute;
    }
  }

  &-header {
    margin-bottom: 45px;
  }

  &-titles {
    margin-bottom: 20px;
  }

  &-inner {
    min-height: 60vh;
    position: relative;
    overflow: hidden;
  }

  &-blocks {
    margin-bottom: 100px;
  }

  & .steps {
    display: flex;
    grid-gap: 15px;
    margin-bottom: 40px;

    & .step {
      width: 52px;
      height: 10px;

      background: #E2E8F0;
      border: 0;
      border-radius: 20px;
      outline: 5px solid transparent;
      transition: all .2s ease-in-out;

      &.active {
        cursor: pointer;
      }
    }

    & .step:first-child {
      background: #00B4FF;
      outline: 5px solid rgba(0, 180, 255, 0.2);
    }

    & .step:nth-child(2).active {
      background: #00D1ED;
      outline: 5px solid rgba(0, 209, 237, 0.2);
    }

    & .step:nth-child(3).active {
      background: #00E6BB;
      outline: 5px solid rgba(0, 230, 187, 0.2);
    }

    & .step:nth-child(4).active {
      background: #90F488;
      outline: 5px solid rgba(144, 244, 136, 0.2);
    }

    & .step:last-child.active {
      background: #F9F871;
      outline: 5px solid rgba(249, 248, 113, 0.2);
    }
  }

  & .step-title {
    font-weight: 400;
    font-size: 20px;
    color: #2D3748;
    margin-bottom: 20px;
  }

  & .step-cards {
    display: flex;
    justify-content: center;

    & > div {
      width: 100%;
    }

    .kind-card {
      cursor: pointer;
      transition: .3s;

      &:hover {
        scale: 1.02;
      }
    }
  }

  & .states-block {
    width: 100%;

    &.active {
      width: calc(100% - 475px);
    }
  }

  & .categories-list,
  & .types-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;

    & button {
      width: 100%;
      min-width: 380px;
      max-width: 420px;
    }

    & .image-card {
      height: 245px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      &::after {
        content: '';

        display: block;
        height: 55%;
        width: 100%;

        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba(0, 43, 111, 0) 0%, #002B6F 100%);
      }

      & span {
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;

        position: absolute;
        bottom: 50px;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        pointer-events: none;
      }
    }
  }

  & .types-list {
    & .image-card {
      min-height: 200px;
      max-height: 220px;
      height: auto;
      padding-bottom: 14%;

      & span {
        bottom: 30px;
      }
    }
  }

  & .kinds-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 367px);
    grid-gap: 30px;

    & .busket-icon {
      & svg {
        fill: #96A0B5;
        transition: all .2s ease-in-out;
      }

      &:hover svg {
        fill: #00B4FF !important;
      }
    }

    & .image-card {
      height: 200px;
    }

    & .kind-data {
      border: 0.3px solid #A0AEC0;
    }

    & .name,
    & .price,
    & .center > div {
      min-height: 45px;

      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 5px;

      padding: 12px 20px;


      & span {
        font-weight: 400;
        font-size: 14px;
        color: #96A0B5;
      }
    }

    & .name {
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      justify-content: start;
    }

    & .center {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border-style: solid;
      border-color: #A0AEC0;
      border-width: .3px 0 .3px 0;

      & .beds svg {
        height: 30px;
        width: 30px;
      }

      & .temperature {
        border-style: solid;
        border-color: #A0AEC0;
        border-width: 0 .3px 0 .3px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      & .size svg {
        height: 24px;
        width: 24px;
      }
    }

    & .price {
      justify-content: space-between;

      & button {
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        cursor: pointer;
        height: -webkit-fill-available;

        & svg {
          fill: #96A0B5;
        }
      }
    }
  }

  & .basket-block {
    width: 475px;
    height: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 0;
    bottom: -18px;
    right: -30px;
    z-index: 10;

    padding: 30px 30px 140px;
    background-color: #EDF2F7;

    & .title {
      width: 100%;
    }

    & .kinds-list {
      width: 100%;
      margin-bottom: 30px;

      & .kind-data {
        max-width: 306px;
        background-color: #fff;
      }

      & .kind-card {
        width: max-content;
        min-height: 108px;

        display: grid;
        grid-template-columns: 108px 1fr;
        background-color: #fff;

        & .image-card {
          height: 100%;
          width: 108px;
        }

        & .name,
        & .price,
        & .center > div {
          min-height: 36px;
          grid-gap: 3.8px;
          padding: 8px 15px;
        }

        & .name {
          font-size: 14px;
        }

        & .center {
          & span {
            font-size: 12px;
          }

          & .beds svg {
            height: 25px;
            width: 25px;
          }

          & .temperature svg {
            width: 16px;
            height: 17px;
          }

          & .size svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }


    & .go-to-basket {
      background: #18CEC9;
      border: none;
      border-radius: 10px;

      padding-left: 50px;
      padding-right: 50px;

      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
    }
  }
}

.sending-application {
  & .basket,
  & .required-building {
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    max-width: 1200px;

    &-item {
      padding: 20px;
      border: 0.3px solid #A0AEC0;
    }

    & .image-card {
      height: 145px;
    }

    & .name {
      font-weight: 600;
      font-size: 20px;
      color: #2D3748;
      margin-bottom: 25px;
    }

    & .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      color: #2D3748;
    }
  }

  & .basket {
    margin-bottom: 56px;

    &-item {
      &.no-active {
        opacity: .4;
      }

      &-top {
        display: grid;
        grid-template-columns: 266px 1fr;
        grid-gap: 30px;
        margin-bottom: 20px;
      }

      &-top-right {
        & .center {
          display: grid;
          grid-template-columns: 1fr 200px;
          grid-gap: 20px;
          justify-content: space-between;
          padding-right: 50px;
        }

        & .infos {
          display: flex;
          flex-direction: column;
          grid-gap: 5px;

          & .info {
            font-weight: 400;
            font-size: 16px;
            line-height: 1;
            color: #96A0B5;

            & span {
              color: #2D3748;
            }
          }
        }
      }

      &-count-and-price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: -30px;

        & .price {
          font-weight: 500;
          font-size: 24px;
          color: #2D3748;
          margin-bottom: 25px;
        }

        & .count-control {
          display: grid;
          grid-template-columns: repeat(3, 40px);
          grid-template-rows: 40px;

          & button,
          & .count {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0.5px solid rgba(160, 174, 192, 0.5);
          }

          & button {
            color: #fff;
            background: rgba(160, 174, 192, 0.5);
            transition: all .2s ease-in-out;
            cursor: pointer;

            &:hover {
              background-color: rgba(160, 174, 192, 0.8);
            }
          }

          & .count {
            font-weight: 500;
            font-size: 16px;
            color: #2D3748;
          }
        }
      }
    }
  }

  & .required-building {
    margin-bottom: 40px;

    &-item {
      display: grid;
      grid-template-columns: 266px 1fr;
      grid-gap: 30px;
    }

    & .name {
      margin-bottom: 20px;
    }
  }

  & .total {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    margin-bottom: 50px;

    & .title {
      font-weight: 600;
      font-size: 20px;
      color: #2D3748;
      margin-bottom: 0;
    }

    & span,
    & span.building::before {
      font-weight: 600;
      font-size: 24px;
      color: #2D3748;
    }

    & span.building::before {
      content: '+';
      margin-right: 20px;
    }
  }
}

.regForm {
  h4 {
    font-weight: 400;
    font-size: 16px;
    color: #2D3748;
    margin: 4px 0 0;
  }

  .ant-input {
    border: none !important;
    background: rgba(237, 242, 247, 0.8) !important;
  }

  .ant-input::placeholder {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #CBD5E0;
  }

  & .upload-dock,
  & .upload-img {
    padding-left: 50px;
    padding-right: 50px;
    border: 1px dashed #128FFF;
    border-radius: 10px;

    font-weight: 600;
    font-size: 18px;
    color: #128FFF;
  }

  & .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 18px;
    margin-top: 20px;

    &::before {
      display: none !important;
    }

    & .ant-upload-list-text-container {
      height: 52px;

      display: flex;
      align-items: center;

      padding: 8px 20px;
      background: rgba(18, 143, 255, 0.1);
      border-radius: 8px;

      &::before,
      &::after {
        display: none !important;
      }

      & .ant-upload-span {
        display: flex;
        align-items: center;
        grid-gap: 22px;
      }
    }

    & .ant-upload-text-icon {
      display: none;
    }

    & .ant-upload-list-item-list-type-text {
      margin: 0;
    }

    & .ant-upload-list-item-name {
      font-weight: 400;
      font-size: 14px;
      text-decoration: underline;
      color: #128FFF;
    }

    & .ant-upload-list-item-card-actions {
      height: 22px;
      width: 22px;
    }

    & .anticon-delete {
      display: block;
      height: 10px;
      width: 10px;
      position: relative;

      & svg {
        display: none;
      }

      &::before {
        content: '';

        display: block;
        width: 10px;
        height: 10px;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNC4zNjI2NiAzLjkyNzFMNy43ODg4NSA3LjM1MzI5TDcuNDMxNjUgNy43MTA0OUw0LjAwNTQ2IDQuMjg0M0wwLjY0NDg4IDcuNjQ0ODhMMC4zMzM0MDkgNy4zMzM0MUwzLjY5Mzk5IDMuOTcyODNMMC4zNjA1OCAwLjYzOTQyTDAuNzE3Nzc4IDAuMjgyMjIyTDQuMDUxMTkgMy42MTU2M0w3LjQwNDQ4IDAuMjYyMzQxTDcuNzE1OTUgMC41NzM4MTNMNC4zNjI2NiAzLjkyNzFaIiBmaWxsPSIjMkQzNzQ4Ii8+Cjwvc3ZnPgo=) center no-repeat;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    & .ant-upload-list-item-card-actions-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
    }
  }

  & .form-btns {
    & button {
      height: auto;
      color: #fff;
      font-weight: 600;
      padding: 20px 30px;
      background: #128FFF;
      border-radius: 10px;
    }

    & .success-button {
      background: #18CEC9;
      border: none;
    }

    & .cancel-button {
      background: #CBD5E0;
    }
  }
}

.createProjectImageHolder {
  height: 77px;
  width: 146px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
  }

  & svg {
    position: absolute;
    top: 7px;
    right: 7px;

    cursor: pointer;
  }
}

.gov-support__header {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;

  /* identical to box height, or 100% */
  letter-spacing: 0.15px;

  color: #2D3748;
}

.gov-support__container {
  height: 246px;
  border: 0.5px solid #A0AEC0;
  width: 1200px;

  padding-top: 20px;
  margin-top: 15px;
  margin-bottom: 45px;

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 20px;

    svg {
      margin-top: 9px;
      margin-right: 19px;
      margin-left: 20px;
    }

    p {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 27px;

      color: #000000;
      
      margin-bottom: 0px;
    }

    a {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 27px;
    }
  }
}