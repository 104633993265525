.mainLayout {
  // .ant-input-group > .ant-input:first-child,
  // .ant-input-group-addon:first-child {
  //   height: 64px;
  // }

  & .select-after {
    & .ant-select-selector{
      width: 190px !important;
    }
    & .ant-select-selection-placeholder,
    & .ant-select-selection-item {
      text-align: start !important;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5OTYgMTEuMzIwOEw1LjU4OTIyIDYuOTEwMDlDNS4yNjM3OCA2LjU4NDY2IDQuNzM2MTQgNi41ODQ2NiA0LjQxMDcgNi45MTAwOUM0LjA4NTI3IDcuMjM1NTMgNC4wODUyNyA3Ljc2MzE3IDQuNDEwNyA4LjA4ODZMOS40MTA3IDEzLjA4ODZDOS43MzYxNCAxMy40MTQgMTAuMjYzOCAxMy40MTQgMTAuNTg5MiAxMy4wODg2TDE1LjU4OTIgOC4wODg2QzE1LjkxNDcgNy43NjMxNyAxNS45MTQ3IDcuMjM1NTMgMTUuNTg5MiA2LjkxMDA5QzE1LjI2MzggNi41ODQ2NiAxNC43MzYxIDYuNTg0NjYgMTQuNDEwNyA2LjkxMDA5TDkuOTk5OTYgMTEuMzIwOFoiIGZpbGw9ImJsYWNrIi8+CjxtYXNrIGlkPSJtYXNrMF8xMzg1Xzc4MzYiIHN0eWxlPSJtYXNrLXR5cGU6bHVtaW5hbmNlIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSI0IiB5PSI2IiB3aWR0aD0iMTIiIGhlaWdodD0iOCI+CjxwYXRoIGQ9Ik05Ljk5OTk2IDExLjMyMDhMNS41ODkyMiA2LjkxMDA5QzUuMjYzNzggNi41ODQ2NiA0LjczNjE0IDYuNTg0NjYgNC40MTA3IDYuOTEwMDlDNC4wODUyNyA3LjIzNTUzIDQuMDg1MjcgNy43NjMxNyA0LjQxMDcgOC4wODg2TDkuNDEwNyAxMy4wODg2QzkuNzM2MTQgMTMuNDE0IDEwLjI2MzggMTMuNDE0IDEwLjU4OTIgMTMuMDg4NkwxNS41ODkyIDguMDg4NkMxNS45MTQ3IDcuNzYzMTcgMTUuOTE0NyA3LjIzNTUzIDE1LjU4OTIgNi45MTAwOUMxNS4yNjM4IDYuNTg0NjYgMTQuNzM2MSA2LjU4NDY2IDE0LjQxMDcgNi45MTAwOUw5Ljk5OTk2IDExLjMyMDhaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTM4NV83ODM2KSI+CjxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0iIzMzOERFMCIvPgo8L2c+Cjwvc3ZnPgo=") !important;
      background-position: right center;
      background-repeat: no-repeat;
    }
  }

  & .auth-logo-to-landing {
    margin-top: 30px;
    margin-left: 25px;

    & svg {
      width: 150px;
      height: 46px;
    }
  }

  .logoSpan {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    padding-top: 3px;
  }

  .ant-layout {
    background: #fff;
  }

  .ant-layout-sider-children {
    height: calc(100vh - 93px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: none;

    .navItemSpan {
      font-weight: 600;
      color: #128FFF;
    }
  }

  .ant-menu-inline .ant-menu-selected::before,
  .ant-menu-inline .ant-menu-item-selected::before {
    content: "";
    background: #e6f7ff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: 8px;
  }

  .ant-menu-inline .ant-menu-selected::after,
  .ant-menu-inline .ant-menu-item-selected::after {
    display: none;
  }

  .ant-menu-item .ant-menu-item-icon,
  .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon,
  .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .anticon + span {
    z-index: 10;
  }

  .ant-menu-item {
    .ant-menu-item-icon {
      &.library-icon {
        path {
          fill: none !important;
        }
      }
    }

    &.ant-menu-item-selected .library-icon path {
      stroke: #1890ff;
    }
  }

  .navItemSpan {
    font-weight: 400;
    color: #A0AEC0;
    margin-left: 10px;
    transition: color .2s ease-in-out;
  }

  .ant-input-group-addon {
    // width: 64px;
    // height: 64px;
    padding: 0 11px !important;
  }

  .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon
  .ant-input-search-button:not(.ant-btn-primary) {
    width: 64px;
    // height: 64px;
  }

  /*
  .ant-select-selector { ///////////////////////////DELETE AFTER DEMO
    border-radius: 10px !important;
    font-size: 14px !important;
    color: #fff;
    background: #39CC36 !important;
  }
  */

  /*  .ant-select-selection-item {
      color: #fff !important;
    }*/

  .myProjectsCell {
    font-weight: normal;

    th {
      background: #f2f6ff;
      color: #697899;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .ant-picker-calendar-header {
    display: none;
  }
}

body {
  background-color: #fcfdff;

  .ant-layout .site-layout {
    background: #fcfdff;
    margin-top: 80px;
  }

  .react-kanban-column {
    background: transparent;
    min-width: 290px;
    width: 24% !important;
    padding: 0;
    height: auto !important;
    display: block !important;

    & > div[data-rbd-droppable-id] {
      height: 100% !important;
    }
  }

  .kanbanHeader {
    margin-bottom: 15px;
    text-align: center;

    &.one {
      background: #f2f6ff;

      button {
        background: #338de0;
        color: #fff;
        font-size: 16px;
      }
    }

    &.two {
      background: #edf2fb;

      button {
        background: #1d62cb;
        color: #fff;
      }
    }

    &.three {
      background: #fff8ec;

      button {
        background: #fcc865;
        color: #fff;
      }
    }

    &.four {
      background: #e9f9f5;

      button {
        background: #28c397;
        color: #fff;
      }
    }

    height: 35px;
    font-size: 16px;
    border-radius: 8px;
    overflow: hidden;

    .colKanabTitle {
      position: relative;
      top: 5px;
    }

    .ant-btn {
      height: 35px;
      width: 35px;
      border: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      float: right;
    }
  }

  .react-kanban-board {
    width: 100%;
    overflow-y: scroll !important;
    display: flex;
    align-items: flex-start;
    max-height: calc(100vh - 221px);

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #cfcfcf;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1890ff;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    & > div {
      width: 100%;
      display: flex;
    }

    [data-rbd-draggable-context-id] {
      width: 100%;
      background: transparent;

      & > div {
        width: 100%;
        background: transparent;
      }
    }
  }

  .cardKanban {
    text-align: left;
    transition: 0.3s;
    background: #fff;
    padding: 0px 20px 20px 20px;
    margin-bottom: 10px;
    border: 0.5px solid #e1e8ef;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    a {
      color: #000;
    }

    &:hover,
    &:active {
      cursor: pointer;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
      border-radius: 3px;
    }

    .userInTask {
      padding: 5px 0;
      display: flex;
      align-items: center;
    }

    .timeBoxKanban {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 15px;
    }

    .titleCardKanban {
      font-size: 14px;
      line-height: 1.4em;
      text-align: left;
      padding-top: 28px;
      margin-bottom: 15px;
    }

    .progress-bar {
      background: #e7edf2;
      width: 100%;
      height: 9px;
      border-radius: 30px;

      .progress-bar-inner {
        height: 9px;
        border-radius: 30px;

        &.small {
          background: #338de0;
        }

        &.medium {
          background: #fcc865;
        }

        &.large {
          background: #28c397;
        }
      }
    }

    .progress-data {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;

      .progress-data-title {
        color: #96a0b5;
      }
    }

    .priority {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;

      .high {
        background: #ffeeef;
        color: #ff575f;
      }

      .medium {
        background: #fff8ec;
        color: #fcc865;
      }

      .low {
        background: #E9F9F5;
        color: #28C397;
      }

      .high,
      .medium,
      .low {
        padding: 9px 18px;
        border-radius: 8px;
      }

      .expire {
        color: #96a0b5;
      }
    }

    .infobar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .members {
        display: flex;

        .userInTask {
          .avatarCanban {
            background-color: #fff;
            overflow: hidden;
            border: 3px solid #fff;
            border-radius: 50%;
          }

          &:nth-child(2) {
            transform: translateX(-50%);
          }

          &:nth-child(3) {
            transform: translateX(-100%);
          }

          &:nth-child(4) {
            transform: translateX(-150%);
          }

          &:nth-child(5) {
            transform: translateX(-200%);
          }

          &:nth-child(6) {
            transform: translateX(-250%);
          }

          &:nth-child(7) {
            transform: translateX(-300%);
          }
        }
      }

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #121f3e;

        // font-family: 'SFProDisplay-Bold';
        font-weight: bold;

        svg {
          height: 22px;
        }
      }
    }

    .kanbanFio {
      color: #a0a0a0;
      margin-left: 5px;
    }
  }

  .cardKanbanEndDate {
    color: #3c4e77;
    border: 1px solid #c6d1dd;
    padding: 9px 18px;
  }

  .arrowToIcon {
    margin: 0 10px 0 15px;
  }

  .ant-modal-header {
    background: #f2f6ff;

    .ant-modal-title {
      font-size: 24px;
      color: #3c4e77;
      // font-family: SFProDisplay-Bold, sans-serif;
      font-weight: bold;
    }
  }

  textarea {
    width: 100% !important;
    border: 1px solid #c6d1dd !important;
    border-radius: 3px !important;
    min-height: 95px !important;
    margin-bottom: 14px !important;
  }

  .ant-btn-lg {
    height: 47px;
    // padding: 0 40px;
  }
}

.avatarCanban {
  width: 33px;
  height: 33px;
  // max-height: 80%;
  border-radius: 100%;
  background-size: cover;
  background-position: 50% 50%;
  flex-shrink: 0;
}

.authSidebar {
  margin-top: 0 !important;

  .support {
    position: absolute;
    bottom: 40px;
  }
}

.support {
  text-align: center;
  width: 100%;

  svg {
    width: 90%;
  }

  svg path {
    stroke: #fff;
    stroke-width: 1px;
    stroke-dasharray: 125;
    stroke-dashoffset: -125;
    stroke-linecap: butt;
    -webkit-transition: all 2s ease-out;
    -moz-transition: all 2s ease-out;
    -ms-transition: all 2s ease-out;
    -o-transition: all 2s ease-out;
    transition: all 2s ease-out;
  }

  &:hover {
    cursor: pointer;

    svg path:first-child,
    svg path:nth-child(2) {
      stroke: #338de0;
      stroke-dashoffset: 0;
      stroke-dasharray: 125;
    }
  }
}

.mapFullScreen {
  width: 100%;
  height: 500px;
}

.mapFullScreenGLobal {
  width: calc(100% + 40px);
  height: calc(100vh - 70px);
  margin-top: -24px;
}

.mapFullScreenGLobal_MainPage {
  width: 100%;
  margin-left: 0px;
  position: relative;
  margin-top: 10px;
  height: 70vh;
  outline: none;
}

.leftSidebar {
  background: white;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  padding-top: 12px;
  margin-top: 80px;
  // box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.05);
}

.logo {
  padding: 25px;
  text-align: center;

  svg {
    width: 80%;

    // g path {
    //   fill: #338de0
    // }

    // g path:nth-child(3) {
    //   fill: #000000;
    // }
  }
}

.logoAuth {
  margin-top: 150px;
  text-align: center;

  svg {
    width: 65%;

    g path {
      fill: #338de0;
    }

    g path:nth-child(3) {
      fill: #000000;
    }
  }
}

.pageTitle {
  margin: 50px 0;
  color: #3c4e77;
  font-size: 24px;
}

.ant-layout-footer {
  background: #fff !important;
}

.ant-btn {
  border-radius: 3px;
}

.ant-btn-primary {
  background: #338de0;
}

.wrapPad {
  padding: 15px 20px;
}

.componentTitle {
  text-align: left !important;
  text-transform: uppercase !important;
  font-size: 24px !important;
  padding-top: 7px !important;
  font-weight: 500 !important;
}

.ant-layout-header {
  padding: 0 12px 0 24px;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
}

.searchField {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 3px;

  input,
  button {
    border: none;
  }

  svg {
    fill: #338de0;
  }

  .ant-btn-lg {
    padding: 0;
  }
}

.innerSearch {
  border: 1px solid #c6d1dd;
  box-sizing: border-box;
  border-radius: 3px;
  height: 47px;
  margin-bottom: 5px;

  .ant-input-group {
    height: 44px;
    display: flex;
    overflow: hidden;
  }

  input,
  button {
    height: 45px !important;
    border: none;
  }

  svg {
    fill: #338de0;
  }
}

.ant-btn-primary {
  svg {
    font-size: 20px;
    position: relative;
    top: 3px;
  }
}

.pl-0 {
  padding-left: 0 !important;
}

.toolbarInner {
  margin-bottom: 13px;
  gap: 20px;
}

.readedDot,
.unreadedDot {
  font-size: 26px;

  &:hover {
    cursor: pointer;
  }
}

.notificateRow p {
  margin: 0;
}

.unreadedDot {
  color: #338de0;
}

.blueText {
  color: #338de0;
}

.helper {
  display: flex;
  align-items: center;
  margin: 15px 0;

  svg {
    margin-right: 10px;
  }
}

.helperGlobal {
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding-left: 12px;
  margin-top: 25px;

  svg {
    margin-right: 10px;
  }
}

.MapToolbar {
  background: #fff;

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #cfcfcf;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #1890ff;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  max-height: 90vh;
  overflow: hidden;
  overflow-y: scroll;

  .actions {
    button {
      height: 54px;
      width: 52px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .activeBtnType {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 3px;

      svg path {
        fill: #338de0;
      }
    }
  }
}

.helpToogler {
  margin-left: 10px;
  transition: 0.3s;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }

  path {
    fill: #338de0 !important;
  }
}

.helpItem {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  margin: 15px 0;
  width: 95%;
  padding: 10px 10px;

  svg {
    margin-right: 10px;

    path {
      fill: #338de0;
    }
  }
}

.formMarkerMaps {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
  z-index: 2;
  position: relative;
  padding: 10px;
  width: 95%;
  background: #fff;
  top: -1px;
}

.header-inner {
  display: flex;
  width: 100%;

  &.investor {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1366px) {
  .logoSpan {
    font-size: 16px !important;
    line-height: 15px !important;
    padding-left: 5px;
  }
}

.contactLinks {
  display: flex;
  align-items: center;
  grid-gap: 15px;

  & p.description {
    max-width: 645px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    text-align: right;
    color: #A0AEC0;
    margin-bottom: 0;
    height: auto;
  }

  & .links a {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    color: #2D3748;

    &:hover {
      text-decoration: underline;
    }
  }
}

.accountRow {
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  border-radius: 8px;
  overflow: visible;
  align-items: center;
  justify-content: flex-end;
  box-shadow: none;
}

.accountButton {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  padding: 12px;
  background: #f3faff;
  border-radius: 0 4px 4px 0;
  border: none;
  height: 54px;
  box-shadow: none !important;
  position: relative;

  & .userPhoto {
    display: flex;
    height: 50px;
    width: 50px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #A3AEBE;

    & .ant-avatar {
      height: 50px !important;
      width: 50px !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  & svg {
    fill: #B0B7C1 !important;
  }

  &:hover,
  &:focus {
    background-color: #F6F6F7;

    & .userDropDown {
      opacity: 1;
      visibility: visible;
    }
  }
}

.userDropDown {
  opacity: 0;
  visibility: hidden;

  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.03));
  transition: all .3s ease-in-out;

  & span.text {
    color: #A3AEBE;
    transition: all 0.3s;
  }

  & .ant-menu-item:hover {
    & span.text {
      color: #338de0;
    }
  }
}

.accountButton2 {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 0 3px 3px 0;
  border: none;
  height: 100%;
}

.exit {
  transform: rotate(180deg);
}

.toolbarHeader {
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 9px;
  margin-bottom: 50px;
  // font-family: SFProDisplay-Regular, sans-serif;
  font-weight: normal;
}

.overlay {
  width: 200px;

  li {
    font-size: 16px;
    padding: 4px;
  }
}

.fullWidthMap {
  margin-left: 0 !important;
  z-index: 3;
}

.fixedBtnMap {
  width: 58px;
  height: 40px;
  display: flex;
  position: fixed;
  z-index: 99;
  left: 270px;
}

.ant-menu-item svg {
  width: 21px;

  path {
    fill: #a0a0a0;
  }
}

.ant-menu-submenu {
  color: #8b96a5;
}

.anticon-safety-certificate {
  // font-size: 16px !important;
  // padding: 0 12px 0 0;
  margin-right: 10px !important;
}

.ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

.anticon-safety-certificate svg,
.anticon-book svg {
  // font-size: 16px !important;
  width: 22px !important;
  height: 21px !important;
}

.ant-menu-item-selected svg path {
  fill: #338de0;
}

.ant-tabs {
  color: #a0a0a0;
}

.ant-tabs-nav {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  margin: 0 21px !important;
}

.ant-tabs-tab {
  margin: 0;
  padding: 20px;
  font-size: 16px;
}

.ant-tabs-content {
  padding: 15px 20px;
}

.float-left {
  float: left;
}

.ant-tabs-ink-bar {
  background: #338de0;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #338de0;
}

.usersTable {
  td {
    padding: 12px 16px;
  }
}

.problemsTable tr:hover {
  cursor: pointer;
}

.greenBtn,
.dangerBtn {
  color: #fff;
  display: inline-flex;
  align-items: center;
  transition: 0.3s;
  padding: 7px 18px;
  margin: 0 10px;

  svg {
    margin-right: 10px;
  }
}

.greenBtn {
  background: #88d486;

  &:hover,
  &:focus {
    color: #fff;
    border-color: #60c65e;
    background: #60c65e;
  }
}

.dangerBtn {
  background: #ffb0b0;

  &:hover,
  &:focus {
    background: #ff9696;
    border-color: #ff9696;
    color: #fff;
  }
}

.passportMapImg {
  max-width: 80%;
  max-height: 170px;
  display: block;
  margin: 20px auto;
}

.VpPagination > div {
  height: 48px;
  padding: 0 14px;
  color: #000;
  display: flex;
  align-items: center;
  background: #fff;
  margin: 10px 0;
  // font-family: SFProDisplay-Medium, sans-serif;
  font-weight: 500;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.border-none {
  border: none;

  .ant-select-selector {
    border: none !important;
  }
}

.secondary-color {
  color: #a0a0a0;
}

.counterBlock {
  display: inline-block;
  border-right: 1px solid #f0f0f0;
  height: 46px;
  padding: 8px 0;
  margin-right: 20px;
}

.pageCounter {
  display: inline-block;
  height: 46px;
  padding: 12px 0;
  border-left: 1px solid #f0f0f0;
  padding-left: 20px;
  border-right: none;
  padding-right: 20px;
}

.navigationPag {
  display: inline-block;

  button {
    height: 48px;
    width: 48px;

    &:first-child {
      border-right: 1px solid #f0f0f0;
      border-radius: 0;
      border-left: 1px solid #f0f0f0;
    }

    &:last-child {
      margin-right: -14px;
    }

    &:hover {
      svg path {
        fill: #338de0;
      }
    }
  }
}

.redIcon > path {
  fill: red;
}

.greenIcon path {
  fill: green;
}

.goldIcon {
  path {
    fill: #ffd700;
  }
}

.actionsIcons {
  path {
    transition: 0.3s;
  }

  margin: 0 12px;

  &:hover {
    cursor: pointer;
  }

  &:hover > path {
    fill: #338de0;
  }
}

.dFlexCenter {
  display: flex;
  align-items: center;
}

.btnUserPanel {
  text-align: left;
  background: #338de0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 8px 0 0 8px;
  min-width: 160px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.notificationsHeader {
  height: 54px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  // box-shadow: 0px 25px 30px rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  position: relative;
}

.rdrMonths {
  flex-wrap: wrap !important;
  justify-content: center;
}

.rdrMonth {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
}

.notifIcon {
  position: absolute;
  top: 0;
  right: 0;
}

.defautlWhiteBg {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
}

.notificateRow {
  border-radius: 3px;
  margin: 10px 0;
  padding: 20px;
  text-align: left;
  display: flex;
  align-items: center;
}

.deleteIcon {
  & > path {
    transition: 0.3s;
    fill: #ff7b7b;
  }

  &:hover {
    cursor: pointer;

    & > path {
      fill: #338de0;
    }
  }
}

.problemTitle {
  float: left;
}

.addProblemsBtn {
  float: left;
  margin-left: 145px;
  margin-bottom: 15px;
}

.problemsTabs {
  width: 100%;
}

.problemForm .ant-row {
  display: flex;
  align-items: center;
}

.errorMap {
  width: 100%;
  height: 400px;
  margin-bottom: 40px;
}

.errorSelect {
  width: 200px;
  border: none !important;
  outline: none !important;
  border-color: #fff;

  .ant-select-selector {
    border-radius: 3px;
    border: none;
    color: #fff;
  }
}

.need_helpSelect {
  .ant-select-selector {
    background: #ff7b7b !important;
  }
}

.resolvedSelect {
  .ant-select-selector {
    background: #39cc36 !important;
  }
}

.in_progressSelect {
  .ant-select-selector {
    background: #ffba7b !important;
  }
}

.leaflet-left {
  left: 25px;
}

li.profileDropdown {
  padding-left: 14px;
}

.demo-option-label-item {
  align-items: center;
  display: flex;

  span {
    padding: 5px;
  }
}

.projectHeader {
  font-size: 24px;
  font-weight: bold;
  margin: 0px 0px 0px 15px;
  text-align: left;
  display: flex;
  align-items: center;
}

.comments_row {
  display: flex;
  flex-direction: column;
}

.ant-comment {
  width: 100%;

  .ant-comment-inner {
    padding: 10px 0;
  }

  .ant-comment-content {
    width: 100%;
    background: rgba(51, 141, 224, 0.08);
    border: 1px solid #f2f6ff;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;

    .ant-comment-content-author-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      color: #000000;
    }

    .ant-comment-content-detail {
      text-align: start;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      color: #121f3e;
    }

    .ant-comment-actions {
      display: flex;
      gap: 7px;

      li {
        width: fit-content !important;

        span {
          font-size: 12px;
          cursor: pointer;
          color: #a0a0a0;
          transition: all 0.2s ease;

          &:hover {
            color: #000;
          }
        }
      }

      width: fit-content !important;
    }
  }
}

.changeLogDiv {
  width: 100%;

  .avatarCanban {
    float: left;
  }

  .textChangelog {
    display: flex;
    text-align: left;

    p {
      margin-left: 10px;
    }
  }
}

.library-toggle-button {
  background: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: #a0a0a0;
  border: none;
  cursor: pointer;
  padding: 22px 34px 26px 34px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 3px;
    background: #338de0;
    opacity: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &.active {
    color: #338de0;
    pointer-events: none;

    &::after {
      opacity: 1;
    }
  }
}

.library-table {
  thead {
    background: #f2f7ff;

    th {
      background: none;
      color: #3c4e77;
    }
  }

  .delete-library-document {
    color: #ff575f;
    cursor: pointer;
  }
}

.add-document-modal {
  border-radius: 3px;

  .ant-modal-body {
    padding: 30px;
  }

  .ant-modal-header {
    border-bottom: none;
    background: #338de0;

    .ant-modal-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.15px;
      color: #ffffff;
    }
  }

  .ant-modal-close {
    svg {
      fill: #fff;
    }
  }

  .ant-switch {
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);

    .ant-switch-handle::before {
      background-color: #e1e8ef;
    }
  }

  .ant-switch-checked {
    background-color: #fff;

    .ant-switch-handle::before {
      background-color: #28c397;
    }
  }

  .ant-btn.ant-btn-link {
    border: 1px solid #338de0;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px !important;

    span {
      color: #338de0;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.15px;
    }
  }
}

.library-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
}

.cantragents-table {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  thead tr th {
    background: #f2f7ff;
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 30px;
  }
}

textarea.my-ant-input:focus {
  border: 1px solid #40a9ff !important;
}

.ant-card.ant-card-bordered {
  border: none;
  background: none;
}

aside {
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.1);
}

aside ul .ant-menu-inline {
  border: none;
}

.toolbarInner.filterTasks {
  .ant-select-selector {
    padding: 8px 11px;
    height: auto;
    border: 0.5px solid #e1e8ef;
    border-radius: 6px;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    text-align: center;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.41506 6.65079L7.24084 11.1142C7.60026 11.5335 8.23156 11.5821 8.65089 11.2227C8.68977 11.1893 8.72603 11.1531 8.75935 11.1142L12.5851 6.65079C12.9446 6.23147 12.896 5.60017 12.4767 5.24074C12.2954 5.08539 12.0646 5 11.8259 5L4.17432 5C3.62203 5 3.17432 5.44772 3.17432 6C3.17432 6.23871 3.25971 6.46955 3.41506 6.65079Z' fill='%23338CDE'/%3E%3C/svg%3E%0A");
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
  }

  .ant-select-selection-item {
    text-align: center;
  }

  .select_arrow_style_stroke {
    & .ant-select-selection-placeholder,
    & .ant-select-selection-item {
      text-align: start !important;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5OTYgMTEuMzIwOEw1LjU4OTIyIDYuOTEwMDlDNS4yNjM3OCA2LjU4NDY2IDQuNzM2MTQgNi41ODQ2NiA0LjQxMDcgNi45MTAwOUM0LjA4NTI3IDcuMjM1NTMgNC4wODUyNyA3Ljc2MzE3IDQuNDEwNyA4LjA4ODZMOS40MTA3IDEzLjA4ODZDOS43MzYxNCAxMy40MTQgMTAuMjYzOCAxMy40MTQgMTAuNTg5MiAxMy4wODg2TDE1LjU4OTIgOC4wODg2QzE1LjkxNDcgNy43NjMxNyAxNS45MTQ3IDcuMjM1NTMgMTUuNTg5MiA2LjkxMDA5QzE1LjI2MzggNi41ODQ2NiAxNC43MzYxIDYuNTg0NjYgMTQuNDEwNyA2LjkxMDA5TDkuOTk5OTYgMTEuMzIwOFoiIGZpbGw9ImJsYWNrIi8+CjxtYXNrIGlkPSJtYXNrMF8xMzg1Xzc4MzYiIHN0eWxlPSJtYXNrLXR5cGU6bHVtaW5hbmNlIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSI0IiB5PSI2IiB3aWR0aD0iMTIiIGhlaWdodD0iOCI+CjxwYXRoIGQ9Ik05Ljk5OTk2IDExLjMyMDhMNS41ODkyMiA2LjkxMDA5QzUuMjYzNzggNi41ODQ2NiA0LjczNjE0IDYuNTg0NjYgNC40MTA3IDYuOTEwMDlDNC4wODUyNyA3LjIzNTUzIDQuMDg1MjcgNy43NjMxNyA0LjQxMDcgOC4wODg2TDkuNDEwNyAxMy4wODg2QzkuNzM2MTQgMTMuNDE0IDEwLjI2MzggMTMuNDE0IDEwLjU4OTIgMTMuMDg4NkwxNS41ODkyIDguMDg4NkMxNS45MTQ3IDcuNzYzMTcgMTUuOTE0NyA3LjIzNTUzIDE1LjU4OTIgNi45MTAwOUMxNS4yNjM4IDYuNTg0NjYgMTQuNzM2MSA2LjU4NDY2IDE0LjQxMDcgNi45MTAwOUw5Ljk5OTk2IDExLjMyMDhaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTM4NV83ODM2KSI+CjxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0iIzMzOERFMCIvPgo8L2c+Cjwvc3ZnPgo=") !important;
    }
  }
}

.riskComponent {
  .ant-tabs-nav {
    margin: 0 10px !important;
  }

  .ant-tabs-content {
    padding: 15px 10px;
  }
}

.archive {
  background: #e7edf2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;

  span {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #96a0b5;
    padding-left: 10px;
  }
}

.activeMenu {
  color: #1890ff;
  border-radius: 8px;

  & > div::after {
    content: "";
    background: #e6f7ff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  path {
    fill: #1890ff;
  }

  i {
    z-index: 1;
  }
}

.ant-menu-item a {
  color: #8b96a5;
}

.profileHead {
  display: flex;
  align-items: center;
  justify-content: end;
}

.accountInfo {
  height: 54px;
  word-break: normal;
  line-height: initial;
  display: flex;
  align-items: center;
  background: #338DE0;
  color: #fff;
  padding: 10px 30px 10px 20px;
  border-radius: 4px 0 0 4px;

  span {
    display: block;
  }
}

.userPhoto {
  margin-right: 11px;
  & .ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}